<template>
  <div class="content content-full content-boxed">
    <div class="block block-rounded">
      <div class="block-content">
        <div v-if="Object.keys(validationErrors).length > 0" class="alert alert-danger" role="alert">
          <h3 class="alert-heading font-size-h4 my-2">Error</h3>
          <p v-for="(error, index) in Object.values(validationErrors)" :key="index" class="mb-0">{{ error }}</p>
        </div>
        <EntityForm v-if="entity._id && !loading" :id="entity._id" v-model="form" :refresh="refresh" :error="validationErrors" />
        <Spinner v-else>Loading...</Spinner>
        <div class="text-right pb-4">
          <button type="submit" class="btn btn-primary" :disabled="loadingAction.update" @click="onUpdate">
            <span v-if="loadingAction.update"><i class="fa fa-spin fa-spinner mr-1"></i> Updating...</span>
            <span v-else><i class="fa fa-check-circle mr-1"></i> Update Entity</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import EntityForm from '@/components/forms/EntityForm';
import Spinner from '@/components/Spinner';

export default {
  name: 'EntityEdit',
  components: {
    Spinner,
    EntityForm
  },
  data() {
    return {
      form: {},
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      entity: 'entity/entity',
      loadingAction: 'entity/loadingAction',
      validationErrors: 'entity/validationErrors'
    })
  },
  async mounted() {
    this.loading = true;
    await this.refresh();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      listEntities: 'entity/list',
      updateEntity: 'entity/update'
    }),
    async refresh() {
      await this.listEntities();

      this.form = {
        ...this.entity,
        _id: undefined
      };
    },
    async onUpdate() {
      await this.updateEntity({ id: this.$route.params.id, data: this.form });

      if (Object.keys(this.validationErrors).length > 0) {
        this.$toasted.error('Unable to update!', { position: 'bottom-right', duration: 3000 });
        return window.scroll(0, 0);
      }

      this.$toasted.success('Entity updated successfully!', { position: 'bottom-right', duration: 3000 });
    }
  }
};
</script>
